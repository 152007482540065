<template>
  <div class="about container-fluid min-vh-100">
    <vue-headful
      title="Install-HC - Redirection vers nouvelle adresse"
      description="Page de redirection de l'ancienne à la nouvelle version du site"
    />
    <div>
      <div v-if="error === true">
        <p>
          Erreur dans la redirection !
        </p>
      </div>
      <h1>Merci de patienter, redirection en cours vers Installation HC Ref : {{ this.code }}</h1>
      <div v-if="install !== null">
        <p>
          <router-link :to="{path: '../presentation/' + urlProprio }">L'Installation Home-Cinema de {{ install }} a été trouvée !</router-link>        
        </p>
      </div>
      <br/><br/>
      <h1>A propos de... <router-link to="/">Install-HC</router-link></h1>            
      <p class="shadow">
        <router-link to="/">Install-HC</router-link> est un site gratuit permettant à ces membres de partager leur passion en publiant leur Installation Home Cinéma.<br/>    
        Qu'elles soient dédiées ou non, débutantes ou confirmées, toutes les Installations Home-Cinema ont leur place sur le site <router-link to="/">Install-HC</router-link>.<br/>
        Chez <router-link to="/">Install-HC</router-link>, nous voyons notre passion commune qu'est le Home Cinéma comme un loisir abordable et ouvert. Vous pourrez largement vous inspirer d'autres réalisations présentées sur le site <router-link to="/">Install-HC</router-link> !<br/>  
        Alors, n'hésitez pas à publier votre Installation Home Cinéma et à venir commenter et échanger avec d'autres passionnés sur le site <router-link to="/">Install-HC</router-link> !<br/>    
        Afin de publier ou commenter sur le site Install-hc, il est nécessaire de <router-link to="/createAccount">créer un compte utilisateur</router-link>.<br/>
        En cas de souci, n'hésitez pas à <router-link to="/contactus">contacter un des administrateurs</router-link> d'<router-link to="/">Install-HC</router-link> qui se fera un plaisir de vous assister !
      </p>
    </div>    
  </div>  
</template>

<script>
// @ is an alias to /src
export default { 
  name: 'about',
  components: {
  },
  props: {
    code: {
      type: String,
      default: null
    }    
  },
  data() {         
    return {
      json: null, 
      windowHeight: 0,
      txt: '',
      actualSize: 0,
      target: '',
      error: null,
      apiUrl: this.apiUrl = "/backend/get/form/route",
      install: null
    }    
  },    
  mounted() {    
    this.getCode()
  },
  computed: {
    urlProprio: function() {
      return this.install.replace(/ /,'___')
    }
  },
  methods: {
    getCode: function() {      
      this.error = null            
      fetch(this.apiUrl,{
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
          body: JSON.stringify({code: this.code})
      })
      .then(res => res.json())
      .then(res => {                
        if(res.proprio) {
          this.install = res.proprio          
          let path = '/presentation/' + this.urlProprio
          let vm = this
          setTimeout(function() {
            vm.$router.push(path)
          },100)
          
        }
        else {            
          this.error = true                        
        }                       
      })
    }  
  }
}

</script>

<style scoped>
.debug {
  display: none;
}

.about {
  margin:0 auto;  
  width:100%;    
  padding:0;
}

h1 {
  font-size:1.05em;
  font-weight:bold;
  text-align: center;
  margin-top:10px;
}

a {
  font-weight: bold;
  font-size:1.1rem;
}

p {
  margin:20px;
  padding:20px;
}

.red{
  color:red;
}

</style>